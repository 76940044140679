import { Field, TextField, Title, PopOverList, Text, Dropdown, Loader } from "components/commons";
import React, { useState } from "react";
import locale from "localization";
import { initialState } from "./validate-payment.state";
import styles from "./validate-payment.module.scss";
import { useApi, useForm, useMount, useModal } from "hooks";
import { validateFuelCode, getFuelCode, validateFleetCard, getFuelCards } from "apis";
import { handleRequest, capitalize } from "utils";
import { StationContext } from "contexts";
import { useContext } from "react";
import { CircularProgress } from "@material-ui/core";
import { ErrorCode, PaymentMethod, PLBTypes } from "enums";
import ValidatePaymentDetails from "./validate-payment-details";
import { useCallback } from "react";
import Big from "big.js";
import { AnnounceVoidTransaction } from "components/modals";
import { Stepper } from "react-form-stepper";
import { prettifyPaymentMethod } from "utils/pretty.utils";
import PurchaseOrderModal from "./purchase-order-modal";

const ValidatePaymentModule = () => {
  const [fuelCards, setFuelCards] = useState([]);
  const { station } = useContext(StationContext);
  const { stationCode, stationId } = station;

  const purchaseOrderModal = useModal();
  const [details, setDetails] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [, setPaymentDetails] = useState(false);
  const [showActiveStep, setActiveStep] = useState(0);

  const { fields, modifyField, modifyForm, applyFieldErrors, clearForm } = useForm({
    initialState,
  });

  const { request: requestValidateFuelCode, loading: validatingFuelCode } = useApi({
    api: validateFuelCode,
    params: {
      stationCode,
    },
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: requestValidateFleetCard, loading: validatingFleetCard } = useApi({
    api: validateFleetCard,
    params: {
      stationCode,
    },
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const { request: requestFuelCode, loading: fetchingFuelCode } = useApi({
    api: getFuelCode,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: requestFuelCards, loading: fetchingFuelCards } = useApi({
    api: getFuelCards,
    params: {
      stationId,
    },
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const fuelCodeStations = (data) => {
    let stationNames = [];
    data &&
      data.forEach((s) => {
        stationNames.push(s.name);
      });
    return stationNames;
  };

  const onChangePurchaseOrderNoCb = useCallback(
    (name, value) => {
      modifyField(name, value);
    },
    [modifyField]
  );

  const validateFleetCardCb = useCallback(
    (fleetCardNo, isFleetCard) => {
      const applyfleetCardNoError = (message) => {
        isFleetCard
          ? applyFieldErrors(
              {
                fleetCardNo: message,
              },
              {
                fleetCardNo: {
                  value: fleetCardNo,
                },
              }
            )
          : applyFieldErrors(
              {
                plbFleetCardNo: message,
              },
              {
                plbFleetCardNo: {
                  value: fleetCardNo,
                },
              }
            );
      };
      if (fleetCardNo && fleetCardNo.length === 16) {
        handleRequest(
          async () => {
            const res = await requestValidateFleetCard({
              fleetCardNo,
            });

            if (res) {
              let driverName = "";
              let driverLicenseId = "";
              let vehicleModel = "";
              let vehiclePlateNumber = "";
              let remarks = "";
              let driverId = "";
              let vehicleId = 0;
              if (res.driver) {
                driverName = `${res?.driver.firstName} ${res?.driver.lastName}`;
                driverLicenseId = res?.driver.driverLicenseId;
                driverId = res?.driver.driverId;
              }

              if (res.vehicle) {
                vehicleModel = res?.vehicle.model;
                vehiclePlateNumber = res?.vehicle.plateNumber;
                remarks = res.vehicle?.remarks;
                vehicleId = res?.vehicle.vehicleId;
              }

              if (
                res?.fleet?.plbType &&
                (res?.fleet?.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO ||
                  res?.fleet?.plbType === PLBTypes.PLB_LOCQPAY_MANUAL_PO_CREDIT)
              ) {
                purchaseOrderModal.show({
                  title: `${locale.enterPurchaseOrderNo}`,
                  description: (
                    <Text align="center" style={{ padding: "0 5rem" }}>
                      {locale.toCompletePurchaseOrderDescription}
                    </Text>
                  ),
                  submit: (value) => {
                    setPaymentDetails(true);
                    setActiveStep(0);
                    setDetails({
                      fleetCardNo,
                      paymentMethod: fields?.paymentMethod.value,
                      fleetId: res?.fleetId,
                      stationCode,
                      driverName,
                      driverLicenseId,
                      vehicleModel,
                      vehiclePlateNumber,
                      remarks,
                      vehicleId,
                      driverId,
                      purchaseOrderNo: value,
                      ...res,
                    });

                    purchaseOrderModal.close();
                  },
                });
              } else {
                setPaymentDetails(true);
                setActiveStep(0);

                setDetails({
                  fleetCardNo,
                  paymentMethod: fields?.paymentMethod.value,
                  fleetId: res?.fleetId,
                  stationCode,
                  driverName,
                  driverLicenseId,
                  vehicleModel,
                  vehiclePlateNumber,
                  remarks,
                  vehicleId,
                  driverId,
                  ...res,
                });
              }
            }
          },
          {},
          async (err) => {
            setDetails(null);
            setPaymentDetails(false);

            switch (err?.data?.errorCode) {
              case ErrorCode.FuelCardIsInValid:
                applyfleetCardNoError(locale.fleetCardIsInvalid);
                break;
              case ErrorCode.FuelCardIsDeActivated:
                applyfleetCardNoError(locale.fleetCardDeActivated);
                break;
              case ErrorCode.FuelCardIsTerminated:
                applyfleetCardNoError(locale.fleetCardTerminated);
                break;
              case ErrorCode.NotActiveDriver:
                applyfleetCardNoError(locale.fleetCardNotActiveDriver);
                break;
              case ErrorCode.NotActiveVehicle:
                applyfleetCardNoError(locale.fleetCardNotActiveVehicle);
                break;
              case ErrorCode.FuelCardDoesNotBelongToThisStation:
                const stationNames = err?.data?.fleetStations?.map((item) => item.name);
                applyfleetCardNoError(
                  <locale.Populate
                    text={locale.fleetCardDoesNotBelongToThisStation}
                    items={[
                      <PopOverList
                        className={styles.popOver}
                        listTitle={locale.redemptionStations}
                        list={stationNames}
                      >
                        <span className={styles.stationListLinkFleetCard}>{locale.stations}</span>
                      </PopOverList>,
                    ]}
                  />
                );
                break;
              default:
                applyfleetCardNoError(err?.data?.message);
                return err.showError(true);
            }
          }
        );
      } else {
        setDetails(null);
      }
    },
    [
      applyFieldErrors,
      fields?.paymentMethod.value,
      requestValidateFleetCard,
      purchaseOrderModal,
      stationCode,
    ]
  );

  const onChangeFuelCodeCb = useCallback(
    (name, value) => {
      modifyField(name, value);

      const fuelCode = value.value;

      const applyFuelCodeError = (message) => {
        applyFieldErrors(
          {
            fuelCode: message,
          },
          {
            fuelCode: {
              value: fuelCode,
            },
          }
        );
      };
      if (fuelCode && fuelCode.length === 10) {
        handleRequest(
          async () => {
            const res = await requestValidateFuelCode({
              fuelCode,
            });

            if (res) {
              let driverName = "";
              let driverLicenseId = "";
              let vehicleModel = "";
              let vehiclePlateNumber = "";
              let remarks = "";
              let driverId = "";
              let vehicleId = 0;

              if (res.drivers && res.drivers.length > 0) {
                driverName = `${res.drivers[0].firstName} ${res.drivers[0].lastName}`;
                driverLicenseId = res.drivers[0].driverLicenseId;
                driverId = res.drivers[0].driverId;
              }

              if (res.vehicles && res.vehicles.length > 0) {
                vehicleModel = res.vehicles[0].model;
                vehiclePlateNumber = res.vehicles[0].plateNumber;
                remarks = res.vehicles[0].remarks;
                vehicleId = res.vehicles[0].vehicleId;
              }

              setDetails({
                ...res,
                paymentMethod: fields?.paymentMethod.value,
                stationCode,
                driverName,
                driverLicenseId,
                vehicleModel,
                vehiclePlateNumber,
                remarks,
                vehicleId,
                driverId,
                literVolume: Big(res.literVolume).minus(res.volumeRedeemed),
              });

              setPaymentDetails(true);

              setActiveStep(0);
            }
          },
          {
            [ErrorCode.FuelCodeDoesNotBelongToAStation]: async () => {
              const fuelCodeResults = await requestFuelCode({
                fuelCode,
              });
              const { fleet } = fuelCodeResults;
              const stationNames = fuelCodeStations(fleet.fleetStations);

              applyFuelCodeError(
                <locale.Populate
                  text={locale.thisCodeCanOnlyBeRedeemdedAt}
                  items={[
                    <PopOverList
                      className={styles.popOver}
                      listTitle={locale.redemptionStations}
                      list={stationNames}
                    >
                      <span className={styles.stationListLink}>{locale.stations}</span>
                    </PopOverList>,
                  ]}
                />
              );
            },
          },
          async (err) => {
            setDetails(null);
            setPaymentDetails(false);
            switch (err.data?.errorCode) {
              case ErrorCode.DeactivatedFuelCode:
                applyFuelCodeError(locale.deactivatedFuelCode);
                break;
              case ErrorCode.NotYetValidFuelCode:
                applyFuelCodeError(locale.fuelCodeIsInactive);
                break;
              case ErrorCode.ExpiredFuelCode:
                applyFuelCodeError(locale.expiredFuelCode);
                break;
              case ErrorCode.ReachedLimitFuelCode:
                applyFuelCodeError(locale.expiredFuelCode);
                break;
              case ErrorCode.InvalidFuelCode:
                applyFuelCodeError(locale.doesNotExistFuelCode);
                break;
              case ErrorCode.FuelCodeNotEnoughBalance:
                applyFuelCodeError(locale.expiredFuelCode);
                break;
              case ErrorCode.NotActiveDriver:
                applyFuelCodeError(locale.deactivatedFuelCode);
                break;
              case ErrorCode.NotActiveVehicle:
                applyFuelCodeError(locale.deactivatedFuelCode);
                break;
              case ErrorCode.InactiveFleet:
                applyFuelCodeError(locale.deactivatedFuelCode);
                break;
              default:
                return err.showError(true);
            }
          }
        );
      } else {
        setDetails(null);
      }
    },
    [
      applyFieldErrors,
      modifyField,
      requestValidateFuelCode,
      stationCode,
      requestFuelCode,
      fields?.paymentMethod.value,
    ]
  );

  const onChangeFleetCardCb = useCallback(
    async (name, value) => {
      try {
        modifyField(name, value);

        const fleetCardNo = value.value;
        await validateFleetCardCb(fleetCardNo, true);
      } catch (error) {
        const applyfleetCardNoError = (message) => {
          applyFieldErrors({
            fleetCardNo: message,
          });
        };
        applyfleetCardNoError(error?.data?.message);
        return error.showError(true);
      }
    },
    [modifyField, validateFleetCardCb, applyFieldErrors]
  );

  const onChangeFuelCardManualPo = useCallback(
    async (name, value) => {
      try {
        modifyField(name, { value: value });
        const fleetCardNo = value;

        await validateFleetCardCb(fleetCardNo, false);
      } catch (error) {
        const applyfleetCardNoError = (message) => {
          applyFieldErrors({
            plbFleetCardNo: message,
          });
        };
        applyfleetCardNoError(error?.data?.message);
        return error.showError(true);
      }
    },
    [modifyField, validateFleetCardCb, applyFieldErrors]
  );

  const activeStepCB = (step) => {
    setActiveStep(step);
  };

  useMount(() => {
    if (station?.isFleetCard) {
      setPaymentMethods([
        {
          value: PaymentMethod.FuelCode,
          label: prettifyPaymentMethod(PaymentMethod.FuelCode),
        },
        {
          value: PaymentMethod.FleetCard,
          label: prettifyPaymentMethod(PaymentMethod.FleetCard),
        },
        {
          value: PaymentMethod.FleetCardManualPO,
          label: prettifyPaymentMethod(PaymentMethod.FleetCardManualPO),
        },
        {
          value: PaymentMethod.PLBLocqpayManualPOCredit,
          label: prettifyPaymentMethod(PaymentMethod.PLBLocqpayManualPOCredit),
        },
      ]);
    } else {
      setPaymentMethods([]);
      modifyField(fields.paymentMethod.name, { value: PaymentMethod.FuelCode });
    }
  });

  const selectPaymentMethod = useCallback(
    async (value) => {
      try {
        let data = {};

        if (
          value === PaymentMethod.FleetCardManualPO ||
          value === PaymentMethod.PLBLocqpayManualPOCredit
        ) {
          data = {
            [fields.plbFleetCardNo.name]: {
              value: "",
              required: true,
              dirty: true,
            },
          };
        }
        const isCredit =
          value === PaymentMethod.PLBLocqpayManualPOCredit
            ? {
                plbType: PaymentMethod.PLBLocqpayManualPOCredit,
              }
            : {};
        const result = await requestFuelCards({ ...isCredit });
        if (result?.fuelCards) {
          const fuelCardResult = result?.fuelCards;
          let fuelCardsList = [];
          fuelCardResult &&
            fuelCardResult.forEach((value) => {
              if (
                !value.fleet.fleetStations ||
                (value.fleet.fleetStations && value.fleet.fleetStations.includes(stationId))
              )
                fuelCardsList.push({
                  label: `${capitalize(value?.fleet?.businessName)} - ${value?.cardNumber}`,
                  value: value?.cardNumber,
                });
            });

          setFuelCards([...fuelCardsList]);
        }
        modifyForm({
          [fields.paymentMethod.name]: {
            value,
          },
          ...data,
        });
      } catch (error) {
        return error.showError(true);
      }
    },
    [requestFuelCards, modifyForm, fields.paymentMethod.name, fields.plbFleetCardNo.name, stationId]
  );
  return (
    <div>
      <div className={styles.intro}>
        <Title className={styles.headerLoader}>{locale.payment}</Title>
        <Stepper
          steps={[
            {
              label: locale.validatePaymentMethod,
            },
            {
              label: locale.actualGasUpDetails,
            },
            { label: locale.payment },
          ]}
          activeStep={showActiveStep}
          connectorStyleConfig={{
            size: 5,
            activeColor: "#3a8dde",
            disabledColor: "#e4e5ec",
            completedColor: "#3a8dde",
          }}
          connectorStateColors={true}
          styleConfig={{
            activeBgColor: "#3a8dde",
            inactiveBgColor: "#e4e5ec",
            completedBgColor: "#3a8dde",
          }}
        />
      </div>
      {!details && (
        <div className={styles.container}>
          {/* <Title className={styles.subtitle}>{locale.validateFuelCodeAndValume}</Title>
          <Text label>{locale.pleaseEnterFuelCode}</Text> */}
          <Title className={styles.subtitle}>{locale.selectPaymentMethodHeader}</Title>
          <Text label>{locale.useEitherFleetCardOrFuelCode}</Text>
          {paymentMethods.length > 0 && (
            <Field {...fields.paymentMethod}>
              {/* <Autocomplete
              {...fields?.paymentMethod}
              options={paymentMethods}
              onChange={(name, { value }) => {
                modifyField(name, { value });
              }}
            /> */}
              <Dropdown
                options={paymentMethods}
                onChange={(value) => {
                  selectPaymentMethod(value);
                }}
                {...fields.paymentMethod}
              />
            </Field>
          )}

          {fields?.paymentMethod.value === PaymentMethod.FleetCard && (
            <Field {...fields.fleetCardNo}>
              <TextField prefix={""} {...fields.fleetCardNo} onChange={onChangeFleetCardCb} />
            </Field>
          )}

          {fields?.paymentMethod.value === PaymentMethod.FleetCardManualPO ||
          fields?.paymentMethod?.value === PaymentMethod.PLBLocqpayManualPOCredit ? (
            <Field {...fields.plbFleetCardNo}>
              {fetchingFuelCards ? (
                <Loader open={fetchingFuelCards} />
              ) : (
                <Dropdown
                  options={fuelCards || []}
                  onChange={(value) => {
                    onChangeFuelCardManualPo(fields.plbFleetCardNo.name, value);
                  }}
                  {...fields.plbFleetCardNo}
                />
              )}
            </Field>
          ) : null}
          {fields?.paymentMethod.value === PaymentMethod.FuelCode && (
            <Field {...fields.fuelCode}>
              <TextField {...fields.fuelCode} onChange={onChangeFuelCodeCb} />
            </Field>
          )}
        </div>
      )}

      {(validatingFuelCode || fetchingFuelCode || validatingFleetCard) && <CircularProgress />}
      {details && (
        <ValidatePaymentDetails
          setPaymentDetails={setPaymentDetails}
          setDetails={setDetails}
          clearForm={clearForm}
          details={details}
          onSuccessCallback={requestValidateFuelCode}
          onActiveStepCB={activeStepCB}
        />
      )}
      <AnnounceVoidTransaction />

      <PurchaseOrderModal
        {...purchaseOrderModal}
        fields={fields}
        onChangePurchaseOrderNoCb={onChangePurchaseOrderNoCb}
        onSubmit={purchaseOrderModal.submit}
        modifyField={modifyField}
      />
    </div>
  );
};

export default ValidatePaymentModule;
